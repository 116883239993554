<template lang="pug">
v-container.negro(v-if='Pie_de_página' fluid).pa-0.ma-0
    //-Contenedor del mapa de sitio, nuestro contacto y encuéntranos en
    Contenedor_de_mapa_nuestro_contacto_encuentranos_en
    Contenedor_de_copyright_y_desarrollado_por
</template>
<style lang="sass">
  .icono_direcciones
    width: 23px!important
  .icono_correo
    width: 27px!important
  .icono_telefono
    width: 25px!important
  .boton_redes
    width: 16px
  .divider_inicial
    max-height: 10px!important
    height: 9px!important
</style>
<script>
export default {
  computed: {
    ruta_actual(){
        return this.$route.name
    },
  },
  methods: {
    dirigir_a(ruta){
      this.$router.replace({name: ruta, params: {}})
      this.$vuetify.goTo("#navegador")
    },
  },
  components: {
    Contenedor_de_mapa_nuestro_contacto_encuentranos_en: ()=>import('./componentes/contenedor_de_mapa_nuestro_contacto_encuéntranos_en'),
    Contenedor_de_copyright_y_desarrollado_por: ()=>import('./componentes/contenedor_de_copyright_y_desarrollado_por'),
  },
}
</script>